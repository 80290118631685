// @flow
import * as React from 'react'
import Flickity from 'react-flickity-component'
import styled, { ThemeContext, withTheme } from 'styled-components'
import { rem } from 'polished'

import { CarouselStyles } from './styles'
import { durations } from '../../styles/animations'

import { mediaQuery, mediaOnly } from '../../styles/media'
import { color } from '../../styles/theme'
import { getFontSize } from '../../styles/style-helpers'
import Ribbon from '../../components/Ribbon/Ribbon'
import Image from '../../components/Image/Image'
import Heading from '../../components/Heading/Heading'
import Markdown from '../../components/Markdown/Markdown'
import Button from '../../components/Button/Button'
import Link from '../../components/Link'
import type { EntryLinkViewModel } from '../../types/EntryLinkViewModel'
import type { EntryLinkCarouselViewModel } from '../../types/EntryLinkCarouselViewModel'
import { useMedia } from '@charlietango/hooks'

export type Props = EntryLinkViewModel

//The const 'fade' needs to be imported here for the carousel fade transitions to work.
//But the library 'flickity-fade' has an issue, therefore it is imported like below.
//Source: https://github.com/yaodingyd/react-flickity-component/issues/4
// eslint-disable-next-line no-unused-vars
const fade =
  typeof window !== 'undefined' ? require('flickity-fade') : () => null

let isTouchCapable = false
if (typeof window !== 'undefined') {
  isTouchCapable =
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch) ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0
}

const EntryLinkWrapper = styled.div`
  flex-direction: column;
  border-radius: ${({ theme }) => rem(theme.borderRadius)};
  ${mediaQuery.md`
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  `};
  width: 100%;
  display: flex;
  justify-content: stretch;
  min-height: 41.75rem;
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  min-height: ${rem(233)};
  border-top-right-radius: ${({ theme }) => rem(theme.borderRadius)};
  border-top-left-radius: ${({ theme }) => rem(theme.borderRadius)};
  ${mediaQuery.md`
    flex-basis: 50%;
    flex-shrink: 0;
    height: ${rem(584)};
    padding-bottom: 0;
    object-fit: contain;
    z-index: 1;
    ${({ reverse, theme }) =>
      reverse
        ? `
          border-top-right-radius: ${rem(theme.borderRadius)};
          border-bottom-right-radius: ${rem(theme.borderRadius)};
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `
        : `
          border-top-left-radius: ${rem(theme.borderRadius)};
          border-bottom-left-radius: ${rem(theme.borderRadius)};
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `}
  `};
`

const ContactTextWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.white};
  background-color: ${({ image, contactBackgroundColor, theme }) =>
    image ? 'transparent' : contactBackgroundColor || theme.primary};
  ${({ image }) => (image ? `text-shadow: 2px 2px rgba(0, 0, 0, 0.2);` : null)}
`

const ContactTextWrapperInner = styled.div`
  width: 100%;
  text-align: center;
`

const ContactTitle = styled.div`
  font-size: ${rem(22)};
  font-weight: 900;
  margin-bottom: ${rem(24)};
  color: ${({ contactTitleColor }) => contactTitleColor || color.white};
`

const ContactText = styled.div`
  font-size: ${rem(42)};
  font-weight: 900;
  color: ${({ contactTextColor, theme }) => contactTextColor || theme.accent};
  transition: transform ${durations.medium} ease-in-out;

  :hover {
    transform: scale(1.05);
  }

  ${mediaQuery.sm`
    font-size: ${rem(56)};
  `}

  ${mediaQuery.md`
    font-size: ${rem(64)};
  `}
`
const FlickityCell = styled(Flickity)`
  ${mediaQuery.md`
    height: ${rem(584)};
   `}
`

const ContentWrapper = styled.div`
  padding: ${rem(32)} ${rem(32)} ${rem(48)};
  border-bottom-right-radius: ${({ theme }) => rem(theme.borderRadius)};
  border-bottom-left-radius: ${({ theme }) => rem(theme.borderRadius)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : color.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${mediaQuery.md`
    justify-content: center;
    padding: 0 ${rem(64)};
    flex-basis: 50%;
    height: ${rem(584)};
    ${({ reverse, theme }) =>
      reverse
        ? `
        border-top-left-radius: ${rem(theme.borderRadius)};
        border-bottom-left-radius: ${rem(theme.borderRadius)};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `
        : `
        border-top-right-radius: ${rem(theme.borderRadius)};
        border-bottom-right-radius: ${rem(theme.borderRadius)};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}
  `}
`

const ContentSection = styled(Heading)`
  && {
    margin-bottom: ${rem(8)};
    ${mediaQuery.md`
      margin-bottom: ${rem(16)};
    `}
    ${({ backgroundColor }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${color.accent};`
        : `color: ${color.white};`}
  }

  letter-spacing: 1px;
  text-transform: uppercase;
`

const ContentHeading = styled(Heading)`
  && {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${textColor || theme.primary};`
        : `color: ${textColor || color.white};`}
  }
`

const ContentText = styled(Markdown)`
  a {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${textColor || theme.primary};`
        : `color: ${textColor || color.white};`}
  }

  && {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor === '#ffffff' || !backgroundColor
        ? `color: ${textColor || theme.primary};`
        : `color: ${textColor || color.white};`}
  }

  ${mediaQuery.md`
    font-size: ${getFontSize('large')};
  `}
`

const ContentButton = styled(Button)`
  margin-top: ${rem(28)};
  ${mediaOnly.sm`
    display: none;
  `}
  ${mediaOnly.xs`
    display: none;
  `}
  ${mediaQuery.md`
    margin-top: ${rem(48)};
  `}
`

const InnerContent = (props: Props) => {
  const isSm = useMedia({ maxWidth: 767 })
  const invertLinkColor =
    props.backgroundColor === '#ffffff' ||
    !props.backgroundColor ||
    (props.theme &&
      props.backgroundColor === props.theme.cardNegativeBackground)

  return (
    <>
      <ImageWrapper {...props}>
        {props.image && <Image {...props.image} cover fillContainer />}
        {((props.contactLink && props.contactLink.label) ||
          props.contactTitle ||
          props.contactText) && (
          <ContactTextWrapper {...props}>
            <ContactTextWrapperInner>
              {props.contactTitle ? (
                <ContactTitle {...props}>{props.contactTitle}</ContactTitle>
              ) : null}
              {props.contactLink ? (
                <Link {...props.contactLink}>
                  <ContactText {...props}>
                    {props.contactLink.label}
                  </ContactText>
                </Link>
              ) : null}
              {props.contactText ? (
                <ContactText {...props}>{props.contactText}</ContactText>
              ) : null}
            </ContactTextWrapperInner>
          </ContactTextWrapper>
        )}
      </ImageWrapper>
      <ContentWrapper {...props}>
        <div>
          {props.topic ? (
            <ContentSection
              responsiveSize
              themed
              tag="p"
              level={5}
              backgroundColor={props.backgroundColor}
              textColor={props.textColor}
            >
              {props.topic}
            </ContentSection>
          ) : null}
          {props.title ? (
            <ContentHeading
              themed
              responsiveSize
              tag="h1"
              level={2}
              backgroundColor={props.backgroundColor}
              textColor={props.textColor}
            >
              {props.title}
            </ContentHeading>
          ) : null}
          {props.text ? (
            <ContentText
              source={
                props.text.length <= (isSm ? 150 : 250)
                  ? props.text
                  : `${props.text.slice(0, isSm ? 149 : 249)}...`
              }
              noMargin
              backgroundColor={props.backgroundColor}
              textColor={props.textColor}
              invert={invertLinkColor}
            />
          ) : null}
        </div>
        {props.link && props.link.href && props.link.label ? (
          <ContentButton
            {...props.link}
            negative={props.buttonNegative}
            type={
              props.theme &&
              (props.theme.name === 'kids' || props.theme.name === 'lgbt')
                ? 'primary'
                : 'normal' //TODO
            }
          >
            {props.link && props.link.label}
          </ContentButton>
        ) : null}
      </ContentWrapper>
    </>
  )
}

function EntryLinkCarousel(props: EntryLinkCarouselViewModel) {
  const { autoPlay = true, autoPlayDuration = 5000, firstBlock, items } = props
  const theme = React.useContext(ThemeContext)

  return (
    <Ribbon paddingTop={firstBlock ? 50 : undefined}>
      <CarouselStyles />
      <FlickityCell
        className={'carousel-cell'}
        options={{
          fade: true,
          accessibility: true,
          autoPlay: autoPlay ? autoPlayDuration : false,
          cellAlign: 'center',
          draggable: isTouchCapable,
          friction: 0.4,
          imagesLoaded: true,
          lazyLoad: 2,
          pageDots: true,
          resize: true,
          selectedAttraction: 0.05,
          wrapAround: true,
          adaptiveHeight: false,
        }}
        static
      >
        {items
          ? items.map((item: EntryLinkViewModel, index) => (
              <EntryLinkWrapper
                reverse={item.reverse}
                key={index + item.text}
                style={
                  index > 0
                    ? {
                        // Set the initial position to ensure we handle serverside rendering
                        position: 'absolute',
                        left: `${100 * index}%`,
                      }
                    : undefined
                }
              >
                {<InnerContent {...item} theme={theme} />}
              </EntryLinkWrapper>
            ))
          : null}
      </FlickityCell>
    </Ribbon>
  )
}

EntryLinkCarousel.displayName = 'EntryLinkCarousel'

export default withTheme(EntryLinkCarousel)
